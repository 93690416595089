
import 'bootstrap'
import AOS from 'aos';
import 'owl.carousel'

// JavaScript
$(document).ready( function () {

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });
  // pagination
  $('ul.pagination .prev .page-link').empty();
  $('ul.pagination .next .page-link').empty();

  //index slider
  // news
  $(".index-news-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    dots: false,
    margin: 20,
    autoplay: false,
    items:3,
    responsive:{
      0:{
          items:1
      },
      991:{
          items:3
      }
    }
  });
  // parents-slider
  $(".parents-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    dots: false,
    margin: 20,
    autoplay: false,
    items:3,
    responsive:{
      0:{
          items:1
      },
      991:{
          items:3
      }
    }
  });
  // teachers-slider
  $(".teachers-slider").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    dots: false,
    margin: 20,
    autoplay: false,
    items:3,
    responsive:{
      0:{
          items:1
      },
      991:{
          items:3
      }
    }
  });
  // workshops
  $(".workshops-slider").owlCarousel({
    items:1,
    margin:0,
    loop: true,
    dots: true,
    nav: false,
  });

  // class tabs
  $(function(){
    var $tab = $('.class-tabs .tabs-btn a.tab');
    $($tab. eq(0) .addClass('active').attr('href')).siblings('.tab-content').hide();

    $tab.click(function(){
        event.preventDefault();
        $($(this). attr ('href')).show().siblings ('.tab-content').hide();
        $(this).addClass('active'). siblings ('.active').removeClass('active');
    });
  });

} );

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    once: true,
  });
});
