import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

const Swal_lg = Swal.mixin({
  width: 900,
  showCloseButton: true,
  showConfirmButton: false,
  customClass: {
    popup: 'popup-hint',
    htmlContainer: 'html-hint',
  },
});

window.Swal = Swal;
window.Swal_lg = Swal_lg;

